// React
import { useEffect, useState, useRef, useMemo } from "react";

// I18Next Import
import { useTranslation } from "react-i18next";

// Components import
import { DashboardCharts } from "./DashboardCharts";
import { DashboardAbandonments } from "./DashboardAbandonments";
import { DashboardResumeFilter } from "./DashboardResumeFilter";
import { DashboardResumeIndicatorSelect } from "./DashboardResumeIndicatorSelect";

// store
import { dashboardState, dashboardAction, dashboardMutations } from "../../store/dashboard/_dashboard.main";

//Types import
import { IFChartStructure, FILTER_CHECK_TYPE, ABANDONMENT_CODES } from "../../types/types.dashboards";

//Utils import
import { dateStringTransform } from "../../utils/utils.dates";

export const DashboardResume = () => {

  const isSubscribed = useRef(true)

  ///
  const [showDocument, setShowDocument] = useState(false)
  const [showRecognition, setShowRecognition] = useState(false)
  const [showValidation, setShowValidation] = useState(false)

  //I18Next
  const [t, i18n] = useTranslation("dashboard");

  // Constants
  const { chartTypeList, summaryChartData, cardDocument, cardRecognition, cardValidation, viewChart } = dashboardState();
  const { getChartTypeList, summaryChartQuery, getBowlAbandonment } = dashboardAction();
  const {setCardDocument, setCardRecognition, setCardValidation} = dashboardMutations();

  const elements = useRef<any[]>([])

  const summaryInfo: any = {
    UserCount: {
      title: `${t("DASHBOARD_RESUME.CAR_USER_TITLE")}`,
      description: `${t("DASHBOARD_RESUME.CAR_USER_DESCRIPTION")}`,
    },
    ProcessCount: {
      title:`${t("DASHBOARD_RESUME.CAR_PROCESSES_TITLE")}`,
      description: `${t("DASHBOARD_RESUME.CAR_PROCESSES_DESCRIPTION")}`,
    },
    /* CompleteCount: {
      title: `${t("DASHBOARD_RESUME.CAR_COMPLETE_TITLE")}`,
      description: `${t("DASHBOARD_RESUME.CAR_COMPLETE_DESCRIPTION")}`,
    }, */
    /* ErrorCount: {
      title: `${t("DASHBOARD_RESUME.CAR_ERROR_TITLE")}`,
      description: `${t("DASHBOARD_RESUME.CAR_ERROR_DESCRIPTION")}`,
    }, */
    SpoofingCount: {
      title: `${t("DASHBOARD_RESUME.CAR_SPOOFING_TITLE")}`,
      description: `${t("DASHBOARD_RESUME.CAR_SPOOFING_DESCRIPTION")}`,
    }
  };

  //Labels chart
  const days = [
    `${t("DASHBOARD_RESUME.SUNDAY")}`,
    `${t("DASHBOARD_RESUME.MONDAY")}`,
    `${t("DASHBOARD_RESUME.TUESDAY")}`,
    `${t("DASHBOARD_RESUME.WEDNESDAY")}`,
    `${t("DASHBOARD_RESUME.TRURSDAY")}`,
    `${t("DASHBOARD_RESUME.FRIDAY")}`,
    `${t("DASHBOARD_RESUME.SATURDAY")}`,
  ];
  
  const chartData = useMemo(()=>{
    const newChartData:any = []
    summaryChartData?.forEach((chart:IFChartStructure)=>{
      const { chartType, startDate, endDate, totalCount } = chart
      let changeFirstLetter = chartType.charAt(0).toLowerCase() + chartType.slice(1)
      let daysValues = [
          chart.sunday,
          chart.monday,
          chart.tuesday,
          chart.wednesday,
          chart.thursday,
          chart.friday,
          chart.saturday,
      ]
      newChartData[chartType] = {
        name: chartType,
        values: daysValues,
        startDate,
        endDate,
        total: totalCount
      }
    })

    return newChartData
  },[summaryChartData])

  const generateOptionsList:any= useMemo(()=>{
    const genArrElements:any[] = []
    const setOptionsToIndicatorsFilter:string[] = [t("DASHBOARD_ABANDONMENT.TITLE")]
    chartTypeList?.forEach((key:any)=>{
      if (summaryInfo[key]) {
        let objectConcat:any = Object.assign(summaryInfo[key], {
          chart: chartData[key]?.name,
          value: chartData[key]?.total,
          startDate: chartData[key]?.startDate,
          endDate: chartData[key]?.endDate,
          series: [
            {
              name: summaryInfo[key].title,
              data: chartData[key]?.values ?? [],
            },
          ],
          categories: days,
        });
        setOptionsToIndicatorsFilter.push(summaryInfo[key].title)
        genArrElements.push(objectConcat)
      }
    })
    elements.current = [...genArrElements]
    return setOptionsToIndicatorsFilter
  },[chartTypeList, summaryChartData, i18n.language])

  const viewChartToLowerCase = viewChart.toLowerCase()
  const dropoutView:string[] = ['all', t("DASHBOARD_ABANDONMENT.TITLE").toLowerCase()]
  
  const setFilteredCharts = useMemo(() => {
      return elements.current.filter((chart:any, index:number)=>{
      const searh = viewChartToLowerCase === 'all' ? '' : viewChartToLowerCase
      const searhIn = chart.title?.toLowerCase()
      return searhIn.includes(searh)
    })
  },[viewChart, elements.current])

  const checkAssigned = FILTER_CHECK_TYPE.TODAY
  let setDatesToSearch = {startDate: '', endDate: ''}
  if((checkAssigned as FILTER_CHECK_TYPE) !== FILTER_CHECK_TYPE.TOTALS){
    const toDay = new Date()
    setDatesToSearch = {
      startDate: dateStringTransform(toDay),
      endDate: `${dateStringTransform(toDay)} 23:59:59`
    }
  }
  
  const handleAbandonment = (dates:{startDate:string; endDate:string}) => {

    getBowlAbandonment({"processId": ABANDONMENT_CODES.FACE_RECOGNITION, ...dates}).then((res)=>{       
      setCardRecognition(res)
      if (res.dropoutCounter >= 0) {
       setShowRecognition(true)          
      }
    });

    getBowlAbandonment({"processId": ABANDONMENT_CODES.FACE_VALIDATION, ...dates}).then((res)=>{      
      setCardValidation(res)
      if (res.dropoutCounter >= 0) {
        setShowValidation(true)          
      }
    });
    
    getBowlAbandonment({"processId": ABANDONMENT_CODES.DOCUMENTATION, ...dates}).then((res)=>{       
      setCardDocument(res);
      if (res.dropoutCounter >= 0) {
        setShowDocument(true)          
      }
    });

    isSubscribed.current = false
  }

  const handleSubmit = (payload:{startDate:string;endDate:string;}) => {
    const { startDate, endDate } = payload

    let searchStructure = {
      startDate,
      endDate
    }

    summaryChartQuery(searchStructure)
    handleAbandonment(searchStructure)
  }

  useEffect(() => {
    if(isSubscribed.current){
      getChartTypeList()
      summaryChartQuery(setDatesToSearch)
      handleAbandonment(setDatesToSearch)
    }    
    //CleanUp
    return ()=>{
      isSubscribed.current = false;
    }
  },[]);

  const recognitionDropout = cardRecognition?.dropoutCounter!
  const recognitionCorrect = cardRecognition?.eventCounter! - cardRecognition?.dropoutCounter!
  const validationDropout = cardValidation?.dropoutCounter!
  const validationCorrect = cardValidation?.eventCounter! - cardValidation?.dropoutCounter!
  const documentDropout = cardDocument?.dropoutCounter!
  const documentCorrect = cardDocument?.eventCounter! - cardDocument?.dropoutCounter!

  return (
    <>
      <div className="dashboard-resumen">
        <h2 className="dashboard-resumen__title">{t("DASHBOARD_RESUME.TITLE_RESUME")}</h2>
        <div className="dashboard-resumen__top">
          <DashboardResumeFilter
            handleSubmit={ handleSubmit }
            arrChart={ summaryChartData! }
            type={ checkAssigned }
          />
          <DashboardResumeIndicatorSelect
            optionsTypes={ generateOptionsList }
          />
        </div>
        <div className="dashboard-resumen__content mt-10">
          {
            dropoutView.includes(viewChartToLowerCase) &&
              <div className="dashboard-resumen__grid--pie">
                <div className="col-span-12">
                  <p className="dashboard-resumen__text--abandonment">{t("DASHBOARD_ABANDONMENT.TITLE")}</p>
                  <div className="flex -ml-7">
                    {showRecognition && 
                      <DashboardAbandonments
                        title={t("DASHBOARD_ABANDONMENT.TITLE_RECOGNITION")}
                        labels={[t("DASHBOARD_ABANDONMENT.TEXT_ABANDONMENT"), t("DASHBOARD_ABANDONMENT.TEXT_SCENARIOS")]}
                        series={[recognitionDropout, recognitionCorrect]}
                      />
                    }
                    {showValidation &&
                      <DashboardAbandonments
                        title={t("DASHBOARD_ABANDONMENT.TITLE_VALIDATION")}
                        labels={[t("DASHBOARD_ABANDONMENT.TEXT_ABANDONMENT"), t("DASHBOARD_ABANDONMENT.TEXT_SCENARIOS")]}
                        series={[validationDropout, validationCorrect]}
                      />
                    }
                    {showDocument &&
                      <DashboardAbandonments
                        title={t("DASHBOARD_ABANDONMENT.TITLE_DOCUMENT")}
                        labels={[t("DASHBOARD_ABANDONMENT.TEXT_ABANDONMENT"), t("DASHBOARD_ABANDONMENT.TEXT_SCENARIOS")]}
                        series={[documentDropout, documentCorrect]}
                      />
                    }
                  </div>
                </div>
              </div>
          }
          {setFilteredCharts.map((item: any, index:number) => (
            <DashboardCharts
              key={ index }
              title={ item.title }
              value={ item.value }
              description={ item.description }
              series={ item.series }
              categories={ item.categories }
              chartName={ item.chart }
              startDate={ item.startDate }
              endDate={ item.endDate }
              onSubmit={ handleSubmit }
            />
          ))}
        </div>
      </div>
    </>
  );
};
