import React from 'react'

// I18Next Import
import { useTranslation } from "react-i18next";

//Elements import
import { SelectClassic } from '../../elements/_elements.main'

//Store import
import { dashboardState, dashboardMutations } from "../../store/dashboard/_dashboard.main";

interface IFDashboardResumeIndicatorSelect{
    optionsTypes:string[];
}
export const DashboardResumeIndicatorSelect = (props:IFDashboardResumeIndicatorSelect) => {

    //I18Next
    const [t, i18n] = useTranslation("dashboard");

    //Store
    const { viewChart } = dashboardState()
    const { setViewChart } = dashboardMutations()

    const setOptions = props.optionsTypes ? props.optionsTypes.sort().map((item:string)=>{
        return {
            value: item.toLowerCase(),
            text: item
        }
    }) : []

    //Add option to the begining
    setOptions?.unshift({value:'all', text:t("DASHBOARD_FILTERS.FIRST_INDICATOR_FILTER_OPTION")})

  return (
    <div>
        <div>
            <label className="dashboard-reume-indicator-select__labe">{t("DASHBOARD_FILTERS.INDICATORS_FILTER_TITLE")}:</label>
            <SelectClassic
                name="types"
                classes='h-10 w-28 bg-white'
                onChange={(e)=>{ setViewChart(e.target.value) }}
                selected={ viewChart }
                options={ setOptions } />
        </div>
    </div>
  )
}
