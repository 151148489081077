import { useEffect, useState } from "react";
// I18Next Import
import { useTranslation } from "react-i18next";
// Store import
import { dashboardState,dashboardAction, dashboardMutations } from "../../store/dashboard/_dashboard.main";

// Components import
import { TableClassic, Pagination } from "../../elements/_elements.main";
import { DashboardFilter } from "./DashboardFilter"
import { DashboardImages } from "./DashboardImages";

//Types import
import { COLORS_TYPE } from "../../types/types.dashboards";

//Utils import
import { mapTableFilter } from "../../utils/utils.map";

// Assets import
import negative from "../../assets/icons/events/face-negative.svg";
import neutral from "../../assets/icons/events/face-neutral.svg";
import successful from "../../assets/icons/events/face-successful.svg";

export const DashboardTable = () => {
  //11/08/2022
  //I18Next
  const [t, i18n] = useTranslation("table");

  const [result, setResult] = useState({})
  const [search, setSearch] = useState<null|{}>(null)
  const [loadingImageStatus, setLoadingImageStatus] = useState({row:'', show: false})

  // Constants
  const { events, totalEvents, showImage, pageNumber, rowsOfPage, searchInEvents } = dashboardState();
  const { requestImage, loadTable } = dashboardAction();
  const { setShowImage, setPageNumber, setRowsOfPage } = dashboardMutations();

  // table title
  const header = [
    `${t('TABLE.TABLE_IMAGE')}`,
    `${t('TABLE.TABLE_USER')}`,
    `${t('TABLE.TABLE_OBSERVATIONS')}`,
    `${t('TABLE.TABLE_DATE')}`,
    `${t('TABLE.TABLE_PROCESS_USER')}`,
    `${t('TABLE.TABLE_PROCESS')}`,
    `${t('TABLE.TABLE_RESULT_PROCESS')}`,
    `${t('TABLE.TABLE_IP')}`,
    `${t('TABLE.TABLE_LATITUDE')}`,
    `${t('TABLE.TABLE_LONGITUDE')}`,
    `${t('TABLE.TABLE_DEVICE')}`,
  ];

  const textDashboardImages:any = {
    process: `${t('TABLE_MODAL_IMAGE.PROCESS')}`,
  }
  
  const imagesType:any = {
    Spoofing: negative,
    Successful: successful,
    Error: negative,
    HumanDetection: neutral,
    DocumentError: neutral,
    DeviceDetection: neutral,
    AccesoriesRecognition: neutral,
    FaceRecognition: neutral,
    DocValidationError: negative,
    DocReadingError: negative,
    DocPhotoReadingError: negative,
    PhotoValidationError: negative,
    ValidationError: negative,
    NotMatch:negative
  }

  const getColor = (payload: any) => {
    return (COLORS_TYPE as any)[payload]
  }

  const tableRows:any= [];  

  events?.forEach((element: any) => {
    delete element.image
    delete element.complete
    delete element.comparePhoto
    delete element.fkProcessId
    delete element.fkUserAppId
    delete element.fkEventTypeId
    delete element.faceId
    delete element.timeSeconds
    delete element.timeMilliseconds

    const tableColums:any= [];
    Object.values(element).forEach((item: any) => {
      tableColums.push(item);
    });
    // console.log("Result:",element.result)
    tableColums.push(imagesType[element.result]);

    tableRows.push(tableColums)
  });
  
  const dateTransform = (payload:any) => {
    let getDate = new Date(payload)
    return getDate.toISOString().split('T')
  }
  const modalImage=(e:any)=>{

    setLoadingImageStatus({row:e[0], show: true})
    requestImage({"id": e[0],"UserId":e[3]})
    .then((data:any)=>{
        setResult({
          data:{
            [`${t('TABLE.TABLE_IMAGE_TEXT_PROCESS')}`]:e[4],
            [`${t('TABLE.TABLE_IMAGE_TEXT_DATE')}`]:e[2],
            [`${t('TABLE.TABLE_IMAGE_TEXT_RESUME')}`]:e[5]
          },
          url:data.image,
          bgColor:(COLORS_TYPE as any)[e[5]]
        })
        setShowImage(true)
        setLoadingImageStatus({row:e[0], show: false})
    });
  }

  //Data for the Pagination
  const options = [
    {value: '', text: 'Seleccione...'},
    { value: 10, text: "10" },
    { value: 20, text: "20" },
    { value: 30, text: "30" },
  ];

  const behind = () => { 
    let pag = 1  
    if (pageNumber > pag) {
      setPageNumber(pageNumber - 1)
    }
  }  
  
  const following = (payload:any) => {   
    if (pageNumber <= payload) {
      setPageNumber(pageNumber + 1)
    }     
  }

  const handleChange = (payload: any) => {
    const minimumPaging = 10
    const getValue = payload.target.value ? payload.target.value : minimumPaging
    setRowsOfPage(getValue)
  }

  const handleSubmit = (payload?:any) => {
    setPageNumber(1)
    setSearch(payload)
  }
  
  useEffect(()=>{
    if(search){
      const dataToSearch = {
        ...mapTableFilter(searchInEvents!),
        PageNumber: pageNumber,
        RowsOfPage: rowsOfPage
      }
      loadTable(dataToSearch)
    }
  },[search, pageNumber, rowsOfPage])

  return (
    <>
      <div className="dashboard-table">
        <div className="dashboard-table__top flex flex--y-center">
          <h2 className="dashboard-table__title">{t('TABLE.TABLE_TITLE')}</h2>
        </div>
        <div className="dashboard-table__content">
          <DashboardFilter handleSubmit={ handleSubmit } />
          <Pagination
            textColor="text-gray-200"
            arrowBackColor="pagination__arrow--color"
            arrowFrontColor="pagination__arrow--color"
            background="bg-gray-800"
            rowOptions={ options }
            selectChange={ handleChange }
            pageNumber={ pageNumber }
            rowsOfPage={ rowsOfPage }
            totalRecords={ totalEvents }
            behind={ behind }
            following={ following }
          />
          
          <TableClassic
            handleImageClick={ modalImage }
            clientIdPosition={ 0 }
            openImg={ true }
            data={ tableRows }
            header={ header }
            getColor ={ getColor }
            colorRowEven="bg-white"
            colorRowOdd="bg-gray-200"
            loading={ loadingImageStatus }
          />
          { showImage && <DashboardImages data ={ result }/>}          
        </div>
      </div>
    </>
  );
};
