import React, { useEffect, useRef } from 'react'

// External libraries import
import { useTranslation } from "react-i18next";

// Components import
import { ButtonClassic, FormClassic, InputClassic, InputDate, SelectClassic } from '../../elements/_elements.main'

// Store import
import { dashboardState, dashboardMutations, dashboardAction } from "../../store/dashboard/_dashboard.main";

//Utils import
import { mapTableFilter } from "../../utils/utils.map";

interface IFDashboardFilter {
    handleSubmit(e:any):void;
}

const defaultProps = {
    handleSubmit:null
}

export const DashboardFilter = (props:IFDashboardFilter) => {
    
    const [t, i18n] = useTranslation("dashboard");

    const btnSeearchRef = useRef<HTMLButtonElement>(null)
   
    const { eventTypeList, processTypeList, devicesList, loadingButtonFilter, searchInEvents } = dashboardState()
    const { setSearchInEvents } = dashboardMutations()
    const { getEventTypeList, getProcessTypeList, getDevicesList } = dashboardAction()
    

    const arrDelete = ['DocumentError', 'NotMatch']

    const optionsTypes:any = eventTypeList?.filter((event:any)=>{
        if(!arrDelete.includes(event.name)){
            return event
        }
    }).map((event:any)=>{
        let newOptions = {
            value: event.id,
            text: event.name
        }
        return newOptions        
    })

    //Add option to the begining
    optionsTypes?.unshift({value:'', text:'Seleccione...'})
    
    const optionsProcess = processTypeList?.filter((event:any)=>{
        if(!arrDelete.includes(event.name)){
            return event
        }
    }).map((event:any)=>{
        let newOptions = {
            value: event.id,
            text: event.name
        }
        return newOptions        
    })

    //Add option to the begining
    optionsProcess?.unshift({value:'', text:'Seleccione...'})
    
    const optionsDevices = devicesList?.map((event:string)=>{
        let newOptions = {
            value: event,
            text: event
        }
        return newOptions        
    })

    //Add option to the begining
    optionsDevices?.unshift({value:'', text:'Seleccione...'})
    
    const handleClickClean = ()=>{
        setSearchInEvents(null)
        let form:any = document.querySelector("#filter")
        form.reset()
    }

    const handleSubmit = () => {
        let dataToSearch = mapTableFilter(searchInEvents!)        
        props.handleSubmit(dataToSearch)
    }

    useEffect(()=>{
        btnSeearchRef.current && btnSeearchRef.current.click()
        getEventTypeList()
        getProcessTypeList()
        getDevicesList()
    },[])

return (
    <>
        <div>
            <FormClassic id="filter" onSubmit={ handleSubmit } classes="dashboard-filter__form">
                <div>
                    <label className="dashboard-filter__label">
                        {t("DASHBOARD_EVENTS.EVENT_ID")}:
                    </label>
                    <InputClassic
                        placeholder={t("DASHBOARD_EVENTS.SEARCH_BY_EVENT_ID")}
                        height="h-10"
                        value={ searchInEvents?.eventID }
                        required={ false }
                        onChange={(e)=>{setSearchInEvents({...searchInEvents!, eventID: e.target.value.trim()})}}
                    />
                </div>
                <div>
                    <label className="dashboard-filter__label">
                        {t("DASHBOARD_EVENTS.USER_ID")}:
                    </label>
                    <InputClassic
                        placeholder={t("DASHBOARD_EVENTS.SEARCH_BY_USER_ID")}
                        height="h-10"
                        width='w-56'
                        value={ searchInEvents?.userID }
                        required={ false }
                        onChange={(e)=>{setSearchInEvents({...searchInEvents!, userID: e.target.value.trim()})}}
                    />
                </div>
                <div className='flex gap-3'>
                    <div>
                        <label className="dashboard-filter__label">
                            {t("DASHBOARD_EVENTS.START_DATE")}:
                        </label>
                        <InputDate name="startDate" value={ searchInEvents?.startDate } onChange={(e)=>{setSearchInEvents({...searchInEvents!, startDate: e.target.value})}} required={ false }/>
                    </div>
                    <div>
                        <label className="dashboard-filter__label">
                            {t("DASHBOARD_EVENTS.END_DATE")}:
                        </label>
                        <InputDate name="endDate" value={ searchInEvents?.endDate } onChange={(e)=>{setSearchInEvents({...searchInEvents!, endDate: e.target.value})}} required={ false }/>
                    </div>
                </div>
                <div>
                    <label className="dashboard-filter__label">Procesos:</label>
                    <SelectClassic name="process" classes='dashboard-filter__select' onChange={(e)=>{setSearchInEvents({...searchInEvents!, process: e.target.value})}} options={ optionsProcess } />
                </div>
                <div>
                    <label className="dashboard-filter__label">
                        {t("DASHBOARD_EVENTS.TYPE")}:
                    </label>
                    <SelectClassic name="types" classes='dashboard-filter__select' onChange={(e)=>{setSearchInEvents({...searchInEvents!, types: e.target.value})}} options={ optionsTypes } />
                </div>
                <div>
                    <label className="dashboard-filter__label">
                        {t("DASHBOARD_EVENTS.DEVICE")}:
                    </label>
                    <SelectClassic name="device" classes='dashboard-filter__select' onChange={(e)=>{setSearchInEvents({...searchInEvents!, devices: e.target.value})}} options={ optionsDevices } />
                </div>
                <div className='ml-auto'>
                    <ButtonClassic
                        type="button"
                        width=""
                        color="error"
                        height="h-10"
                        text={t("DASHBOARD_EVENTS.CLEAN")}
                        onClick={ handleClickClean }
                        classes='mr-2'
                    />
                    <ButtonClassic
                        ref={ btnSeearchRef }
                        type="submit"
                        width="w-28"
                        height="h-10"
                        loading={ loadingButtonFilter }
                        text={t("DASHBOARD_EVENTS.SEARCH")}
                        onClick={()=>{}}
                    />
                </div>
            </FormClassic>
        </div>
    </>
    )
}

DashboardFilter.defaultProps = defaultProps