import { EllipsisLoader } from "../_elements.main";

// I18Next Import
import { useTranslation } from "react-i18next";

//Props
interface IFTableClassic {
  clientIdPosition: number;
  openImg: boolean;
  colorRowEven: string;
  colorRowOdd: string;
  header: any;
  data: [];
  viewImage(e: any): void;
  getColor(e: any): string;
  handleImageClick(e: any): void;
  onChange(e: any): void;
  onInput(e: any): void;
  onBlur(e: any): void;
  onClick: () => void;
  loading:any;
  messagetableEmpty?:string
}
//valor x default
const defaultProps = {
  clientIdPosition: 0,
  openImg: false,
  colorRowEven: "bg-gray-800",
  colorRowOdd: "bg-gray-900",
  header: null,
  data: null,
  viewImage: null,
  getColor: null,
  handleImageClick: null,
  onChange: null,
  onClick: null,
  onInput: null,
  onBlur: null,
  loading: null,  
};

export const TableClassic = (props: IFTableClassic) => {
  const [t, i18n] = useTranslation("table");
  const color = (payload: string) => {
    let setColor: any = {
      yellow: "border-yellow-400",
      red: "border-red-500",
      green: "border-primary",
    };
    return setColor[payload];
  };

  return (
    <>
      <div className="table__container-table">
        <table className="table-fixed">
          <thead>
            <tr>
              {props.header.map((title: any, indTitle: number) => (
                <th
                  key={`th-${indTitle}`}
                  scope="col"
                >
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.data.map((row: any, index) => {
              return (
                <tr
                  key={row[0]}
                  className={`${
                    index % 2 ? props.colorRowEven : props.colorRowOdd
                  }`}
                >
                  {props.openImg && (
                    <td className="p-3 mx-auto"
                    >
                      <div
                        className={`table__container--img                        
                        ${color(
                          props.getColor(row[6])
                        )}`}
                      >
                        { props.loading.row === row[0] && props.loading.show
                          ? <EllipsisLoader/>
                          : <img
                            src={row[row.length - 1]}
                            alt=""
                            className="w-14 h-14 object-cover "
                            onClick={() => props.handleImageClick(row)}
                          />
                        }
                      </div>
                    </td>
                  )}
                  {row.map((col: any, indexCol: number) => {
                    if (indexCol !== row.length - 1) {
                      return (
                        <td
                          key={`tr-${indexCol}`}
                          className="py-2"
                        >
                          <p className="table__text">{col}</p>
                        </td>
                      );
                    }
                  })}               
                </tr>
              );
            })}
          </tbody>
        </table>
        {props.data.length===0 &&
              <div className="table__message--empty">
                <p className="m-auto">{props.messagetableEmpty??`${t('TABLE.TABLE_MESSAGE_EMPTY')}`}</p>
              </div>
            }
      </div>
    </>
  );
};
TableClassic.defaultProps = defaultProps;
