//peticion
import create from "zustand";

// Types imports
import { IFDashboards } from "../../types/types.dashboards";

export const dashboardState = create<IFDashboards>(() => ({
    showImage: false,
    dataTable: {
        events: {},
        totalRecords: 0
    },
    dashboard: {},
    searchData: [], //<- TODO: Validate to delete
    chartTypeList: [],
    summaryCardData: null,
    summaryChartData: null,
    viewChart: 'all',
    usersChartData: null,
    processChartData: null,
    completedChartData: null,
    errorsChartData: null,
    spoofingChartData: null,
    loadingButtonCharts: false,
    loadingButtonFilter: false,
    tableData: null,
    userDataModel: {},
    totalEvents: 0,
    events: [],
    pageNumber: 1,
    rowsOfPage: 10,
    eventTypeList: [],
    processTypeList: [],
    devicesList: [],
    modalImageUser: false,
    cardDocument:null,
    cardRecognition:null,
    cardValidation:null,
    searchInEvents:null,
 
}));
