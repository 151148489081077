//peticion externas
import create from "zustand";
import { restPost, restGet } from "../../utils/utils.rest";
// Store
import { dashboardMutations } from "./dashboard.Mutation";
import { alertsMutations } from "../alerts/_alerts.main";
import { loginMutations } from "../login/_login.main";

// EndPoints import
import {
  URL_SUMMARY_CARD_COUNT,
  URL_CHART_TYPE_LIST,
  URL_SUMMARY_CHART_COUNT,
  URL_GET_PAGINATED,
  URL_USERS_CHART,
  URL_PROCESS_CHART,
  URL_COMPLETED_CHART,
  URL_ERRORS_CHART,
  URL_SPOOFING_CHART,
  URL_GET_IMAGE,
  URL_GET_PROCESS_LIST,
  URL_GET_DEVICES_LIST,
  URL_GET_EVENTS_LIST,
  URL_GET_EVENTS_MENU_LIST,
  URL_ABANDONMENT
} from "../../app.tickets";

const setAlertsMutation = alertsMutations.getState();
const setDashboardMutations = dashboardMutations.getState();
const setLoginMutations = loginMutations.getState();

export const dashboardAction = create(() => ({

  summaryCardQuery: async (payload: any) => {
    try {
      const res = await restPost({ url: URL_SUMMARY_CARD_COUNT, data: payload });
      const cardData = await res.data.chartTypes;
      setDashboardMutations.setSummaryCardData(cardData);
    } catch (error) {
      console.error(error);
    }
  },

  getChartTypeList: async () => {
    try {
      const res = await restGet({ url: URL_CHART_TYPE_LIST });
      const setData = await res.data.data.chartTypes
      setDashboardMutations.setChartTypeList(setData);
    } catch (error) {
      console.error(error);
    }
  },

  summaryChartQuery: async (payload: any) => {
    setDashboardMutations.setLoadingButtonCharts(true);
    setLoginMutations.setLoadingPage({status: true})
    try {
      const res = await restPost({ url: URL_SUMMARY_CHART_COUNT, data: payload });
      const chartsList = await res.data.data;
      setDashboardMutations.setSummaryChartData(chartsList);
    } catch (err:any) {
      const response = err.response;
      const data = {
        code: response.status,
        title: response.statusText,
        message: response.data.message,
        alertType: "modal",
      };
      setAlertsMutation.setInfoAlert(data);
    } finally {
      setDashboardMutations.setLoadingButtonCharts(false);
      setTimeout(()=>{
        setLoginMutations.setLoadingPage({status: false})
      },1000)
    }
  },

  getUsersChart: async (payload:any) => {
    try {
      const res = await restPost({ url: URL_USERS_CHART, data: payload });
      const userChart = await res.data.data;
      setDashboardMutations.setUsersChartData(userChart);
    } catch (error) {
      console.error(error);
    }
  },

  getProcessChart: async (payload:any) => {
    try {
      const res = await restPost({ url: URL_PROCESS_CHART, data: payload });
      const processChart = await res.data.data;
      setDashboardMutations.setProcessChartData(processChart);
    } catch (error) {
      console.error(error);
    }
  },

  getCompletedChart: async (payload:any) => {
    try {
      const res = await restPost({ url: URL_COMPLETED_CHART, data: payload });
      const completedChart = await res.data.data;
      setDashboardMutations.setCompletedChartData(completedChart);
    } catch (error) {
      console.error(error);
    }
  },

  getErrorsChart: async (payload:any) => {
    try {
      const res = await restPost({ url: URL_ERRORS_CHART, data: payload });
      const errorsChart = await res.data.data;
      setDashboardMutations.setErrorsChartData(errorsChart);
    } catch (error) {
      console.error(error);
    }
  },

  getSpoofingChart: async (payload:any) => {
    try {
      const res = await restPost({ url: URL_SPOOFING_CHART, data: payload });
      const spoofingChart = await res.data.data;
      setDashboardMutations.setSpoofingChartData(spoofingChart);
    } catch (error) {
      console.error(error);
    }
  },

 loadTable: async (payload: any) => {
  setLoginMutations.setLoadingPage({status: true})
  setDashboardMutations.setLoadingButtonFilter(true);
  try {
   const res = await restPost({ url: URL_GET_PAGINATED, data: payload });
   const data = res.data.data;
   const totalEvents = parseInt(data.totalEvents);
   setDashboardMutations.setTotalEvents(totalEvents);
   setDashboardMutations.setEvents(res.data.data.events);
  } catch (error) {
   setDashboardMutations.setTotalEvents(0);
   setDashboardMutations.setEvents([]);
  } finally {
    setDashboardMutations.setLoadingButtonFilter(false);
    setTimeout(()=>{
      setLoginMutations.setLoadingPage({status: false})
    },1000)
  }
 },

 requestImage: async (payload: any) => {
   try {
     const res = await restPost({url:URL_GET_IMAGE,data:payload})
     return res.data.data;     
   } catch (error) {
     console.log(error);     
   }
 },

 getEventTypeList: async () => {
  try {
    const res = await restGet({url:URL_GET_EVENTS_LIST})
    setDashboardMutations.setEventTypeList(res.data.data)
  } catch (error) {
    console.log(error);
  }
 },
 
 getEventTypeMenuList: async () => {
  try {
    const res = await restGet({url:URL_GET_EVENTS_MENU_LIST})
    setDashboardMutations.setEventTypeList(res.data.data)
  } catch (error) {
    console.log(error);
  }
 },

 getProcessTypeList: async () => {
  try {
    const res = await restGet({url:URL_GET_PROCESS_LIST})
    setDashboardMutations.setProcessTypeList(res.data.data)
  } catch (error) {
    console.log(error);
  }
 },
 
 getDevicesList: async () => {
  try {
    const res = await restGet({url:URL_GET_DEVICES_LIST})
    setDashboardMutations.setDevicesList(res.data.data)
  } catch (error) {
    console.log(error);
  }
 },

 getBowlAbandonment: async (payload: any) =>{
  try {
    const res = await restPost({url:URL_ABANDONMENT,data:payload})
    return (res.data.data)
  } catch (err) {
    console.log(err);
  }
 }

}));
