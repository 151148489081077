//Types import
import { IFTableFilter } from "../types/types.dashboards";

export const mapTableFilter = (payload:IFTableFilter) => {
    let data = payload ?? {}
    let dataToSearch = {}
    const eventFilter:any = {
        eventID: 'id',
        userID: 'UserId',
        startDate: 'StartDateFilter',
        endDate:  'EndDateFilter',
        types:  'EventTypeFilter',
        process: 'ProcessTypeFilter',
        devices: 'Device',
    }

    Object.entries(data).forEach((item)=>{
        const key = eventFilter[item[0]]
        const value = item[1]
        value && Object.assign(dataToSearch,{[key]: value})
    })

    return dataToSearch
}