import { useEffect } from "react";

interface Props {
  children: any;
  classes: string;
  size: "small" | "medium" | "large";
  outsideClick(e: any): void;
}

const defaultProps = {
  classes: "",
  size: "small",
  closeOnClickOutside: false,
  outsideClick: null,
};

export const ModalClassic = (props: Props) => {
  const handleGlobalClick = (e: any) => {
    return (
      props.outsideClick &&
      props.outsideClick(e.target.classList.contains("modal"))
    );
  };

  useEffect(() => {
    window.addEventListener("click", handleGlobalClick);
    //CleanUp click listener
    return () => window.removeEventListener("click", handleGlobalClick);
  });

  const type: any = {
    small: "modal__container--small",
    medium: "modal__container--medium",
    large: "modal__container--large",
  };

  const size = () => {
    return type[props.size];
  };

  return (
    <>
      <div className="modal">
        <div
          className={`
                modal__container
                ${size()}
                ${props.classes}
            `}
        >
          {props.children}
        </div>
      </div>
    </>
  );
};

ModalClassic.defaultProps = defaultProps;
