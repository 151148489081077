const ENDPOINT = process.env.REACT_APP_ENDPOINT

//RefreshToken
export const URL_REFRESH_TOKEN = `${ENDPOINT}/Auth/RefreshToken`;
export const SAVE_AUTH_LOCAL_STORAGE = 'auth';

// Autentication url
export const URL_AUTH = `${ENDPOINT}/Auth`;
export const URL_AUTH_LOGIN = `${ENDPOINT}/Auth/App`;
export const URL_AUTH_OTP = `${ENDPOINT}/Auth/VerifyOTP`;
export const URL_AUT_REQ_OTP = `${ENDPOINT}/Auth/SendOTP`;
export const URL_AUT_RES_PASS = `${ENDPOINT}/Auth/ResetPassword`;
export const URL_AUT_CHANG_PASS = `${ENDPOINT}/Auth/ChangePassword`;
//Manager OTP
export const URL_AUTH_OTP_GENERATE = `${ENDPOINT}/otp/Generate`;
export const URL_AUTH_OTP_VALIDATE = `${ENDPOINT}/otp/Validate`;
export const URL_AUTH_OTP_REGENERATE = `${ENDPOINT}/otp/Regenerate`;

// Consumers url
export const URL_GET_BY_EVENT_TYPE = `${ENDPOINT}/UsersApp/GetByEventType`;
export const URL_GET_FILTERED_CONSUMERS = `${ENDPOINT}/UsersApp/GetByContainsId`;
export const URL_GET_INITIAL_CONSUMERS = `${ENDPOINT}/UsersApp/GetLatestRows`;
export const URL_GET_CONSUMER = `${ENDPOINT}/Event/GetByUserAppPaginated`;
export const URL_GET_PAGINATED = `${ENDPOINT}/Event/GetPaginated`;
export const URL_GET_IMAGE = `${ENDPOINT}/Event/GetImage`;
// Events url
export const URL_SUMMARY_CARD_COUNT = `${ENDPOINT}/DataCount`;
export const URL_CHART_TYPE_LIST = `${ENDPOINT}/ChartType`;
export const URL_SUMMARY_CHART_COUNT = `${ENDPOINT}/Event/Chart`;
export const URL_USERS_CHART = `${ENDPOINT}/Event/Chart/Users`;
export const URL_PROCESS_CHART = `${ENDPOINT}/Event/Chart/Process`;
export const URL_COMPLETED_CHART = `${ENDPOINT}/Event/Chart/Complete`;
export const URL_ERRORS_CHART = `${ENDPOINT}/Event/Chart/Errors`;
export const URL_SPOOFING_CHART = `${ENDPOINT}/Event/Chart/Spoofing`;

export const URL_GET_EVENTS_LIST = `${ENDPOINT}/EventType`;
export const URL_GET_EVENTS_MENU_LIST = `${ENDPOINT}/EventTypeMenu`;
export const URL_GET_PROCESS_LIST = `${ENDPOINT}/Process`;
export const URL_GET_DEVICES_LIST = `${ENDPOINT}/Event/GetDevices`;

// User url
export const URL_USER = `${ENDPOINT}/User`;
export const URL_PASSWORD_CHANGE = `${ENDPOINT}/User/UpdatePassword`;
// Client URL
export const URL_SERVICE_CHANGE = `${ENDPOINT}/Client/EnabledDisabled`;
// Flux 
export const URL_ABANDONMENT = `${ENDPOINT}/Flux/dropouts`;